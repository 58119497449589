<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class='appdaochu'>
        <p class='appdaochu_tit type-input-box'>
            <span v-for="(i,index) in daochu_chaxun_list" @click="dangqian_daochu_num=index" :class="index==dangqian_daochu_num?'zise':''" :key="index">{{i}}</span>
        </p>
        <ul>
            <li><p>表单类型:</p>
                <el-select class="shiyong_biaodan_xiala_kuang type-input" v-model="flow_type">
                    <el-option
                    class="type-input-s"
                    v-for="item in shenpi_liebiao"
                    :key="item.flow_id"
                    :label="item.flow_name"
                    :value="item.flow_id">
                    </el-option>
                </el-select>
                <!-- <el-select v-model="zhuangtai_id" clearable placeholder="请选择">
                    <el-option
                    v-for="item in zhuangtai_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select> -->
            </li>
            <!-- <li><p>标题:</p>
                <input type="text" placeholder="标题关键字" v-model="biaoti_text">
            </li>
            <li><p><span>*</span>发起时间:</p>
                <el-date-picker
                    v-model="start_faqi"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
                ~
                <el-date-picker
                    v-model="end_faqi"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
            </li>
            <li><p><span>*</span>完成时间:</p>
                <el-date-picker
                    v-model="start_wancheng"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
                ~
                <el-date-picker
                    v-model="end_wancheng"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
            </li> -->
        </ul>
        <p class="appdaochu_btn">
            <!-- <span @canplay="dianji_chaxun">查询</span> -->
            <span @click="dianji_daochu">导出</span>
        </p>
    </div>
</template>

<script>
import { query_flow_group_info_list, query_form_manage_power_list } from '../../api/api.js'
export default {
  name: 'appdaochu',
  data () {
    return {
      daochu_chaxun_list: ['数据导出'],
      // ,'数据导出记录'
      dangqian_daochu_num: '0',
      flow_type: '',
      shenpi_liebiao: [],
      biaoti_text: '',
      start_faqi: '',
      end_faqi: '',
      start_wancheng: '',
      end_wancheng: '',
      zhuangtai_list: [],
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/'
    }
  },
  created () {
    this.jichu()
  },
  mounted () {

  },
  watch: {
    // dangqian_daochu_num(){

    // }
  },
  methods: {
    jichu () {
      if (this.$jichuxinxi().ent_info != undefined && this.$jichuxinxi().ent_info.staff_level == 1) {
        query_flow_group_info_list({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const list = []
            date.forEach(item => {
              this.$set(item, 'name', item.flow_group_name)
              item.children.forEach(it => {
                list.push(it)
              })
            })
            this.flow_type = list[0].flow_id
            this.shenpi_liebiao = list
          } else if (res.data.code == 500) {}
        })
      } else {
        query_form_manage_power_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const list = []
            date.forEach(item => {
              this.$set(item, 'name', item.flow_group_name)
              item.children.forEach(it => {
                list.push(it)
              })
            })
            this.flow_type = list[0].flow_id
            this.shenpi_liebiao = list
          } else if (res.data.code == 500) {}
        })
      }
    },
    //   点击查询
    dianji_chaxun () {

    },
    // 点击导出
    dianji_daochu () {
      console.log(this.flow_type)

      let date = ''
      date = this.url + 'appr_set_export?ent_id=' + this.$ent_id() + '&flow_id=' + this.flow_type
      // let name=this.$jichuxinxi().ent_info.ent_name
      // this.shenpi_liebiao.forEach(item=>{
      //     if(item.flow_id==this.flow_type){
      //         name=name+item.flow_name
      //     }
      // })
      this.$func.fujian_xiazai3(date)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.appdaochu {
    height:100%;
    padding: 0.32rem 0.29rem;
.el-input__inner{
  font-size: 10px!important;
}
    .appdaochu_tit{
        display: flex;
        align-items: center;
        span{
            font-size: 0.16rem;
            color:#1A2533;
            margin-bottom: 0.36rem;
            // display: inline-block;
            // padding: 0.04rem 0.12rem;
            // cursor: pointer;
            // border-radius: 0.04rem;
            // border:1px solid #eee;
            // margin-right: 0.04rem;
            // color:#888;
        }
        // .zise{
        //     border:1px solid #9a86db;
        //     color:#9a86db;
        // }
    }
    ul{
        li{
            display: flex;
            align-items: center;
            p{
                margin-right: 0.1rem;
            }
        }
    }
    .appdaochu_btn{
        display: flex;
        align-items: center;
        margin-top:0.2rem;
        // justify-content: flex-end;
        span{
            display: inline-block;
            padding: 0.08rem 0.2rem;
            color:#fff;
            background-color: #9a86db;
            border-radius: 0.04rem;
            cursor: pointer;
            margin-right:0.24rem;
        }
    }
}
</style>
